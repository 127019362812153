export const formatValue = (number: number) => {
  const [integer, decimal] = String(number).split('.'); // Разделяем целую и дробную части
  const digits = integer.split('');
  let formattedNumber = '';

  for (let i = 0; i < digits.length; i++) {
    formattedNumber += digits[i];
    if ((digits.length - i - 1) % 3 === 0 && i !== digits.length - 1) {
      formattedNumber += ' ';
    }
  }

  return decimal ? `${formattedNumber}.${decimal}` : formattedNumber;
};