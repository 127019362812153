import { Button } from 'UI/Button/Button'
import { useTranslation } from 'react-i18next'

export const ModalAttention = ({onClose, goTo}: {onClose?: () => void, goTo?: () => void}) => {

  const {t} = useTranslation()

  return (
    <>
      <p style={{fontFamily:'Manrope, Inter, Helvetica, Arial, sans-serif'}}>{t("Реквизиты меняются с каждым платежом, будьте внимательны при последующих оплатах.")}</p>
      <Button variant='yellow' onClick={goTo}>{t("Подтвердить")}</Button>
      <Button onClick={onClose} variant='outline'>{t("Отмена")}</Button>
    </>
  )
}